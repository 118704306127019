/**
 * Bailey design components.
 */

import { useCallback, useState } from 'react';

import { BadgeColor, BadgeType } from '../lib/types';

/**
 * COLORS.
 */

export enum Colors {
  background = 'gray-50', // Figma: FAFAFA
  black = 'gray-900', // Figma: 101010
  darkRed = 'red-700', // Figma: EA7575
  lightRed = 'red-200', // Figma: FFD1D1
  secondaryText = 'gray-400', // Figma: 989CA5
  pink = 'pink-300',
}

export type StyleProps = { className?: string };

// 14 point light gray text.
export const Detail: React.FC<StyleProps> = ({ children, className }) => {
  return <p className={`text-sm text-${Colors.secondaryText} ${className}`}>{children}</p>;
};

export const Divider: React.FC<StyleProps> = ({ className }) => (
  <div className={`bg-gray-200 flex-shrink-0 ${className}`} style={{ height: 1 }} />
);

type BadgeProps = { color: BadgeColor; type: BadgeType } & StyleProps;

export const Badge: React.FC<BadgeProps> = ({ color, type, className }) => {
  let backgroundColor = '';
  let textColor = '';

  switch (color) {
    case BadgeColor.red:
      backgroundColor = 'bg-red-100';
      textColor = 'text-red-400';
      break;
    case BadgeColor.pink:
      backgroundColor = 'bg-pink-100';
      textColor = 'text-pink-400';
      break;
    case BadgeColor.purple:
      backgroundColor = 'bg-purple-100';
      textColor = 'text-purple-400';
      break;
  }

  return (
    <div className={`flex content-center ${backgroundColor} px-2 lg:px-3 py-1 lg:py-1.25 ${className}`}>
      <p className={`text-tiny lg:tracking-wide font-medium uppercase ${textColor}`}>{type}</p>
    </div>
  );
};

type CounterProps = {
  defaultValue?: number;
  onChange: (count: number) => void;
} & StyleProps;

export const Counter: React.FC<CounterProps> = ({ className, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue || 0);

  const increment = useCallback(() => {
    onChange(value + 1);
    setValue(value + 1);
  }, [onChange, value]);

  const decrement = useCallback(() => {
    if (value > 1) {
      onChange(value - 1);
      setValue(value - 1);
    }
  }, [onChange, value]);

  return (
    <div className={`flex items-center h-14 border rounded-full flex-shrink-0 flex-grow-0 ${className || ''}`}>
      <div className="w-14 h-14 flex items-center justify-center cursor-pointer" onClick={decrement}>
        <img src="/images/minus.png" className="w-4" />
      </div>
      <p className="mx-4 font-medium w-6 text-center">{value}</p>
      <div className="w-14 h-14 flex items-center justify-center cursor-pointer" onClick={increment}>
        <img src="/images/plus.png" className="w-4" />
      </div>
    </div>
  );
};
