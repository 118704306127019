import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useAuth } from '../lib/auth';
import ProfileMenu from './ProfileMenu';

interface NavbarProps {
  cartCount?: number;
  className?: string;
  lightDesktop?: boolean;
  showDismiss?: boolean;
  onAction: () => void;
  onShowCart?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ cartCount, className, lightDesktop, showDismiss, onAction, onShowCart }) => {
  const router = useRouter();
  const { user, logout } = useAuth();

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    interactive: true,
  });

  const onHome = useCallback(() => router.push('/feed'), [router]);
  const onRoute = useCallback((path: string) => router.push(path), [router]);

  const onLogout = useCallback(() => {
    if (user) {
      console.info(`Logging out user ${user.id}`);
    }

    // We may have a token but no user, still do the logout.
    logout();
    router.push('/');
  }, [logout, router, user]);

  const hasItems = !!cartCount;
  const colorClass = lightDesktop ? 'lg:bg-none lg:bg-white' : '';

  return (
    <div
      className={`sticky flex-shrink-0 left-0 top-0 right-0 px-5 h-18 flex shadow-md lg:shadow-none z-30 bg-gradient-to-r from-max-pink via-max-pink to-br-blue ${colorClass} ${className}`}
    >
      <div className="flex items-center content-center w-full lg:max-w-5xl mx-auto lg:px-4">
        <Image
          src="/images/nav-logo.png"
          alt="Logo"
          width={94}
          height={16}
          onClick={onHome}
          className="cursor-pointer"
        />
        {showDismiss ? (
          <img className="ml-auto w-4 mr-2 cursor-pointer" src="/images/x-black.png" alt="menu" onClick={onAction} />
        ) : (
          <div className="ml-auto flex items-center">
            <div className="hidden lg:flex items-center justify-between mr-8 text-gray-700">
              <Link href="/about">
                <span className="text-xs uppercase mt-1 pb-1 tracking-widest border-gray-500 hover:border-b cursor-pointer">
                  About
                </span>
              </Link>
              <Link href="/faq">
                <span className="text-xs uppercase ml-6 mt-1 pb-1 tracking-widest border-gray-500 hover:border-b cursor-pointer">
                  FAQ
                </span>
              </Link>
            </div>

            <div
              className="hidden lg:flex w-9 h-9 mr-1 bg-clear border border-gray-900 rounded-full cursor-pointer items-center justify-center"
              ref={setTriggerRef}
            >
              <img src="/images/icon.svg" alt="" className="w-6" />
            </div>
            <img
              className="w-8 mr-1 lg:hidden cursor-pointer"
              src="/images/menu-black.png"
              alt="menu"
              onClick={onAction}
            />

            <div className="hidden lg:flex items-center ml-7" onClick={() => (onShowCart ? onShowCart() : '')}>
              <div className="w-px h-5 bg-gray-lite bg-opacity-50" />
              <div className={`flex items-center ml-6 ${hasItems ? 'cursor-pointer' : ''}`}>
                <div
                  className={`flex items-center justify-center h-6 w-6 ${
                    hasItems ? 'bg-green-400 text-white' : 'bg-gray-lite bg-opacity-25 text-gray-lite'
                  }`}
                >
                  <p className="text-xs pb-2px">{cartCount ? cartCount : '0'}</p>
                </div>
                <p className="ml-4 text-sm text-gray-lite">Cart</p>
                <img src="/images/arrow-right.png" alt="" className="w-1.5 ml-3" />
              </div>
            </div>

            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-container settings z-50 mt-2 p-0 min-w-60' })}
              >
                {/* <div {...getArrowProps({ className: 'tooltip-arrow' })} /> */}
                <ProfileMenu
                  hasActiveDeliveries={false}
                  isDesktop={true}
                  user={user}
                  onLogout={onLogout}
                  onRoute={onRoute}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Navbar);
