import { memo } from 'react';

import { User } from '../lib/types';
import { Divider } from './general';

enum Setting {
  'about' = 'About',
  'history' = 'Order History',
  'shop' = 'Shop',
  'faq' = 'FAQ',
  'logout' = 'Sign Out',
}

interface ProfileMenuProps {
  className?: string;
  hasActiveDeliveries: boolean;
  isDesktop?: boolean;
  onLogout: () => void;
  onRoute: (path: string) => void;
  user: User;
}

const DESKTOP_SETTINGS: Setting[] = [];

const MOBILE_SETTINGS: Setting[] = [Setting.about, Setting.faq];

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  className,
  hasActiveDeliveries,
  isDesktop,
  onLogout,
  onRoute,
  user,
}) => {
  const settings = isDesktop ? [...DESKTOP_SETTINGS] : [...MOBILE_SETTINGS];
  if (user) {
    settings.unshift(Setting.history);
    settings.push(Setting.logout);
  }

  return (
    <div className={`flex flex-col ${className}`}>
      {settings.map((setting, idx) => {
        const isLast = idx === settings.length - 1;
        return (
          <div
            key={setting}
            className=""
            onClick={() => {
              if (setting === Setting.about) {
                onRoute('/about');
              }
              if (setting === Setting.history) {
                onRoute('/orders');
              }
              if (setting === Setting.shop) {
                window.open('https://postdates.myshopify.com');
              }
              if (setting === Setting.faq) {
                onRoute('/faq');
              }
              if (setting === Setting.logout) {
                onLogout();
              }
            }}
          >
            <p
              key={setting}
              className={`text-lg lg:text-base font-medium py-5 hover:bg-gray-50 lg:px-4.5 cursor-pointer ${
                setting === Setting.history && hasActiveDeliveries ? 'text-green-400' : ''
              }`}
            >
              {setting}
            </p>
            {!isLast && <Divider className="lg:mx-4.5" />}
          </div>
        );
      })}
    </div>
  );
};

export default memo(ProfileMenu);
